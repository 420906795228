<template>
  <div class="header-nav-wrapper pt-6 pb-6">
      <div class="container lg:px-0">
        <div class="md:flex">
            <div class="w-full md:w-5/12 lg:w-6/12 flex justify-center md:justify-start pb-4 md:pb-0">
              <router-link to="/">
                <img class="md:pr-12 lg:pr-0 pt-2 md:pt-0" src="/images/logo.png" alt="vst ecs logo">
              </router-link>
            </div>
            <div class="w-7/12 md:w-7/12 lg:w-6/12 flex justify-end hidden md:block pt-5 md:pt-0 lg:pt-2">
              <div class="md:flex md:justify-end">
                <div class="text-left text-right">
                  <p class="leading-tight text-sm md:text-md text-lavender font-common letter-spacing pt-2 pb-1 md:pb-0">CONTACT US AT</p>
                  <a href="tel:09178933352" class="text-sm md:text-2xl font-common-bold leading-tight text-dark hover:text-purple-700 transition-effect">
                    <span>0917 893 3352</span>
                  </a>
                  <span class="text-sm md:text-2xl font-common-bold leading-tight text-purple-700">&nbsp;|&nbsp;</span>
                  <a href="tel:0283650887" class="text-sm md:text-2xl font-common-bold leading-tight text-dark hover:text-purple-700 transition-effect">
                    <span>02 8365 0887</span>
                  </a>
                </div>
                <div class="pl-2 pl-2 hidden lg:block">
                  <img class="md:mr-1" src="/images/tel-icon.png" alt="contact icon">
                </div>
              </div>
            </div>
            <div class="w-full block md:hidden border-t-2 pt-5 mt-1">
              <table cellpadding="0" cellspacing="0" class="w-full">
                <tr>
                  <!-- <td width="5%" ></td> -->
                  <td width="100%" class="text-center">
                    <p class="leading-tight text-sm text-lavender letter-spacing ">CONTACT US AT</p>
                    <a href="tel:09178933352" class="text-lg font-common-bold leading-tight text-dark hover:text-lavender-600 transition-effect">
                      <span>(0917 893 3352</span>
                    </a>
                    <span class="text-lg font-common-bold leading-tight text-purple-700">&nbsp;|&nbsp;</span>
                    <a href="tel:+0283650887" class="text-lg font-common-bold leading-tight text-dark hover:text-lavender-600 transition-effect">
                      <span>02 8365 0887</span>
                    </a>
                  </td>
                  <!-- <td width="10%" >
                    <img width="20px" class="md:mr-1" src="/images/tel-icon.png" alt="contact icon">
                  </td> -->
                  <!-- <td width="5%" ></td> -->
                </tr>
              </table>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Headernav'
}
</script>

<style>
.header-nav-wrapper {
  background-color: #fff;
}
</style>
