<template>
  <div class="body-wrapper">
    <Headernav />
    <div class="header-banner-wrapper">
      <div class="container">
        <div class="w-full md:w-6/12 bg-lavender banner-title-container pt-8 pb-10 pr-6 pl-6">
          <div class="banner-title text-left pb-8">Transform Your Home <br class="hidden lg:block">into a Masterpiece</div>
          <div class="btn-width"><a href="#contact-area" class="black-btn">Inquire Now!</a></div>
        </div>
      </div>
    </div>

    <div class="w-full section">
      <div class="container">
        <div class="w-full text-title text-lavender text-center pb-12">
          <span class="text-dark">Our</span> Products
        </div>
        <div class="w-full lg:flex">
          <div class="w-full lg:w-4/6 pb-8 lg:pr-6">
            <div class="w-full">
              <button id="modalBtn01" class="product-btn w-full">
                <div class="product-content-btn pt-3">
                  <div class="product-bg" style="background: url('/images/product/1.png');"></div>
                  <div class="w-full txt-lg text-dark font-bold pt-5 pb-5">Frameless Shower Enclosure</div>
                </div>
              </button>
            </div>
          </div>

          <div class="w-full lg:w-4/6 pb-8 lg:pr-3 lg:pl-3">
            <div class="w-full">
              <button id="modalBtn02" class="product-btn w-full">
                <div class="product-content-btn pt-3">
                  <div class="product-bg" style="background: url('/images/product/2.png');"></div>
                  <div class="w-full txt-lg text-dark font-bold pt-5 pb-5">Sliding Frameless</div>
                </div>
              </button>
            </div>
          </div>

          <div class="w-full lg:w-4/6 pb-8 lg:pl-6">
            <div class="w-full">
              <button id="modalBtn03" class="product-btn w-full">
                <div class="product-content-btn pt-3">
                  <div class="product-bg" style="background: url('/images/product/3.png');"></div>
                  <div class="w-full txt-lg text-dark font-bold pt-5 pb-5">Wardrobe Sliding Door</div>
                </div>
              </button>
            </div>
          </div>

          <!-- ============ modal content ============ -->
          <!-- ====== modal 1 ======= -->
          <div id="modal01" class="modal">
            <div class="modal-content">
              <div class="w-full flex justify-end text-right pt-4 pb-4">
                <div class="close01 close-modal">&times;</div>
              </div>
              <div class="w-full">
                  <img class="m-auto" src="/images/product/1.png" alt="product">
              </div>
            </div>
          </div>
          <!-- ====== modal 2 ======= -->
          <div id="modal02" class="modal">
            <div class="modal-content">
              <div class="w-full flex justify-end text-right pt-4 pb-4">
                <div class="close02 close-modal">&times;</div>
              </div>
              <div class="w-full">
                  <img class="m-auto" src="/images/product/2.png" alt="product">
              </div>
            </div>
          </div>
          <!-- ====== modal 3 ======= -->
          <div id="modal03" class="modal">
            <div class="modal-content">
              <div class="w-full flex justify-end text-right pt-4 pb-4">
                <div class="close03 close-modal">&times;</div>
              </div>
              <div class="w-full">
                  <img class="m-auto" src="/images/product/3.png" alt="product">
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:flex">
          <div class="w-full lg:w-4/6 pb-8 lg:pr-6">
            <div class="w-full">
              <button id="modalBtn04" class="product-btn w-full">
                <div class="product-content-btn pt-3">
                  <div class="product-bg" style="background: url('/images/product/4.png');"></div>
                  <div class="w-full txt-lg text-dark font-bold pt-5 pb-5">Stained Glass</div>
                </div>
              </button>
            </div>
          </div>

          <div class="w-full lg:w-4/6 pb-8 lg:pr-3 lg:pl-3">
            <div class="w-full">
              <button id="modalBtn05" class="product-btn w-full">
                <div class="product-content-btn pt-3">
                  <div class="product-bg" style="background: url('/images/product/5-thumb.jpg');"></div>
                  <div class="w-full txt-lg text-dark font-bold pt-5 pb-5">Italian Hydraulic Shower Hinge</div>
                </div>
              </button>
            </div>
          </div>

          <div class="w-full lg:w-4/6 pb-6 lg:pl-6"></div>

          <!-- ============ modal content ============ -->
          <!-- ====== modal 4 ======= -->
          <div id="modal04" class="modal">
            <div class="modal-content">
              <div class="w-full flex justify-end text-right pt-4 pb-4">
                <div class="close04 close-modal">&times;</div>
              </div>
              <div class="w-full">
                  <img class="m-auto" src="/images/product/4.png" alt="product">
              </div>
            </div>
          </div>
          <!-- ====== modal 5 ======= -->
          <div id="modal05" class="modal">
            <div class="modal-content">
              <div class="w-full flex justify-end text-right pt-4 pb-4">
                <div class="close05 close-modal">&times;</div>
              </div>
              <div class="w-full">
                  <img class="m-auto" src="/images/product/5.jpg" alt="product">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full bg-light-lavender section">
      <div class="container ">
        <div class="w-full ">
          <div class="w-full text-title text-lavender text-center pb-8">
            <span class="text-dark">Visit Our</span> Branches
          </div>
          
          <div class="w-full md:flex">
            <div class="w-full md:w-4/6 pb-8 md:pr-6">
              <div class="store-height bg-lavender-gradient">
                <div class="w-full p-5">
                  <div class="w-full text-white text-bold text-center store-title border-2 border-purple-900 bg-purple-500 p-3 pt-5">
                    SGO PHILS, INC.
                  </div>
                  <div class="w-full text-white text-left pl-3 pr-3 pt-6 pb-6">
                    277(d) D. Tuazon, San Jose, Quezon City 1115
                  </div>
                  <div class="w-full pl-3 lr-3 pb-6">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/telno.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1 text-white">
                          <a href="tel:83650887" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_phil('tel:83650887')">8 365 0887</a>, <br>
                          <a href="tel:83651355" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_phil('tel:83651355')">8 365 1335</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="w-full pl-3 lr-3">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/mobile.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:0917-8933352" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_phil('tel:0917-8933352')">0917-8933352</a><br>
                          <a href="tel:0999-9931639" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_phil('tel:0917-8933352')">0999-9931639</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/6 pb-8 md:pl-3 md:pr-3">
              <div class="store-height bg-lavender-gradient">
                <div class="w-full p-5">
                  <div class="w-full text-white text-bold text-center store-title border-2 border-purple-900 bg-purple-500 p-3 pt-5">
                    SGO ORTIGAS HOME DEPOT
                  </div>
                  <div class="w-full text-white text-left pl-3 pr-3 pt-6 pb-6">
                    Dona Julia Vargas Ave. Pasig City
                  </div>
                  <div class="w-full pl-3 lr-3 pb-6">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/telno.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:86384117" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_pasig('tel:86384117')">8 638 4117</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="w-full pl-3 lr-3">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/mobile.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:09178136935" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_pasig('tel:09178136935')">0917-8136935</a><br>
                          <a href="tel:09399198375" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_pasig('tel:09399198375')">0939-9198375</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/6 pb-8 md:pl-6">
              <div class="store-height bg-lavender-gradient">
                <div class="w-full p-5">
                  <div class="w-full text-white text-bold text-center store-title border-2 border-purple-900 bg-purple-500 p-3 pt-5">
                    SGO ALABANG HOME DEPOT
                  </div>
                  <div class="w-full text-white text-left pl-3 pr-3 pt-6 pb-6">
                    Zapote Road WestGate Alabang
                  </div>
                  <div class="w-full pl-3 lr-3 pb-6">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/telno.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:87712233" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_alabang('tel:87712233')">8 771 2233</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="w-full pl-3 lr-3">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/mobile.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:0917-8990182" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_alabang('tel:87712233')">0917-8990182</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:flex">
            <div class="w-full md:w-4/6 pb-8 md:pr-6">
              <div class="store-height bg-lavender-gradient">
                <div class="w-full p-5">
                  <div class="w-full text-white text-bold text-center store-title border-2 border-purple-900 bg-purple-500 p-3 pt-5">
                    SGO BALINTAWAK HOME DEPOT
                  </div>
                  <div class="w-full text-white text-left pl-3 pr-3 pt-6 pb-6">
                    EDSA Balintawak Quezon City
                  </div>
                  <div class="w-full pl-3 lr-3 pb-6">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/telno.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:89210087" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_balintawak('tel:89210087')">8 921 0087</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="w-full pl-3 lr-3">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td valign="top" width="15%" class="pr-1">
                          <img class="m-auto" src="/images/mobile.png" alt="contact">
                        </td>
                        <td width="85%" class="pr-1">
                          <a href="tel:0917-8990186" class="font-common text-white hover:text-purple-300 transition-effect" onclick="gtag_report_conversion_sgo_balintawak('tel:0917-8990186')">0917-8990186</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/6 pb-8 md:pl-3 md:pr-3 hidden md:block"></div>

            <div class="w-full md:w-4/6 pb-8 md:pl-6 hidden md:block"></div>
          </div>

        </div>
      </div>
    </div>

    <div class="w-full bg-lavender section">
      <div class="container">
        <div class="w-full text-title text-white text-left pb-6">
          <span class="text-white">About</span> Us
        </div>
        <div class="w-full text-common text-white text-justify">
          S.G.O. Phils., Inc. began operating with only two employees. Customer satisfaction over S.G.O.’s quality products and services is the main driving force behind the company’s continuous growth.
          <br><br>
          Strategically located showrooms made S.G.O. Phils., Inc. more accessible to clients and several residential subdivisions in fast rising development areas of Metro Manila. This further strengthens S.G.O. Phils., Inc. brand equity when it comes to stained glass, shower enclosures, glass etching, and wardrobe doors.
        </div>
      </div>
    </div>

    <div class="w-full bg-white section">
      <div id="contact-area" class="container">
        <div class="w-full text-title text-lavender text-center pb-6">
          <span class="text-dark">Get In Touch</span> With Us
        </div>
        <div class="w-full">
          <!-- feedback.activamedia.com.sg script begins here -->
          <!-- <iframe allowTransparency="true" class="form-height" style="overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="//feedback.activamedia.com.sg/my-contact-form-5447856.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe> -->
          <!-- feedback.activamedia.com.sg script ends here -->

          <contactform />
        </div>
      </div>
    </div>


    <div class="footer-bg">
      <div class="w-full lg:flex">
        <div class="w-full block lg:hidden pt-10 pl-6 pr-6 pb-6">
            <div class="w-full text-xl font-common-bold text-white pb-1">
              Company Address
            </div>
            <div class="w-full font-common text-white pb-8">
              277(d) D. Tuazon, Brgy. San Jose, Quezon City 1115
            </div>
            <div class="w-full text-xl font-common-bold text-white pb-1">
              Landline Number
            </div>
            <div class="w-full pb-8">
              <a href="tel:0283650887" class="font-common text-white hover:text-purple-300 transition-effect">(02) 8365-0887</a>
              <span class="text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <a href="tel:0283651335" class="font-common text-white hover:text-purple-300 transition-effect">(02) 8365-1335</a>
            </div>
            <div class="w-full text-xl font-common-bold text-white pb-1">
              Mobile Number
            </div>
            <div class="w-full pb-8">
              <a href="tel:+639178933352" class="font-common text-white hover:text-purple-300 transition-effect">(63) 917 893 3352</a>
            </div>
            <div class="w-full text-xl font-common-bold text-white pb-1">
              E-mail Address
            </div>
            <div class="w-full pb-10">
              <a href="mailto:sgo277d@gmail.com" target="_blank" class="font-common text-white hover:text-purple-300 transition-effect">sgo277d@gmail.com</a>
            </div>
        </div>
        <div class="w-full lg:w-6/12 hidden lg:block"></div>
        <div class="w-full lg:w-6/12">
          <iframe class="map-height" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15441.095695718446!2d120.995442!3d14.640386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dcd920da1057440!2sSGO%20Philippines%20Inc!5e0!3m2!1sen!2sph!4v1614155793731!5m2!1sen!2sph" width="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
      <div class="w-full contact-info-wrapper hidden lg:block">
        <div class="container lg:flex contact-info">
          <div class="w-5/12">
            <div class="w-full text-xl font-common-bold text-white pb-1">
              Company Address
            </div>
            <div class="w-full font-common text-white pb-8">
              277(d) D. Tuazon, Brgy. San Jose, Quezon City 1115
            </div>
            <div class="w-full text-xl font-common-bold text-white pb-1">
              Landline Number
            </div>
            <div class="w-full pb-8">
              <a href="tel:0283650887" class="font-common text-white hover:text-purple-300 transition-effect">(02) 8365-0887</a>
              <span class="text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <a href="tel:0283651335" class="font-common text-white hover:text-purple-300 transition-effect">(02) 8365-1335</a>
            </div>
            <div class="w-full text-xl font-common-bold text-white pb-1">
              Mobile Number
            </div>
            <div class="w-full pb-8">
              <a href="tel:+639178933352" class="font-common text-white hover:text-purple-300 transition-effect">(63) 917 893 3352</a>
            </div>
            <div class="w-full text-xl font-common-bold text-white pb-1">
              E-mail Address
            </div>
            <div class="w-full pb-10">
              <a href="mailto:sgo277d@gmail.com" target="_blank" class="font-common text-white hover:text-purple-300 transition-effect">sgo277d@gmail.com</a>
            </div>
          </div>
          <div class="w-7/12"></div>
        </div>
      </div>
    </div>


    <div class="w-full bg-black text-white text-center pt-5 pb-6">
      <p>Maintained and Developed by <a href="https://www.amph.com.ph/" target="_blank" class="transition-effect hover:text-lavender-400">AMPH Advertising Agency Inc.</a></p>
    </div>
  </div>
</template>
<script>
import Headernav from '@/components/Headernav.vue'
import contactform from '@/components/contactform.vue'
import carousel from 'vue-owl-carousel'
import vueSmoothScroll from 'vue-smooth-scroll'
export default {
  components: {
    Headernav,
    contactform,
    vueSmoothScroll,
    carousel
  }
}
</script>
<style>
</style>
